var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class Analytics extends Component {
    constructor() {
        super('Analytics', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('body').on('hw-cookies-setting-traffic-changed', (event) => {
                if (event.detail.isEnabled) {
                    this.enableMatomo();
                }
            });
            this.success();
        });
    }
    enableMatomo() {
        let _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        _paq.push(['alwaysUseSendBeacon']);
        _paq.push(['setTrackerUrl', '//kanakosawada.com/matomo/matomo.php']);
        _paq.push(['setSiteId', '1']);
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = '/matomo/matomo.js';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(scriptTag, s);
    }
}
